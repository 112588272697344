export default {
  menuItems: [
    {
      name: 'ダッシュボード',
      link: '/dashboard'
    },
    {
      name: 'チャート',
      link: '/detail',
    },
    {
      name: '閾値設定',
      link: '/threshold',
    },
    {
      name: 'メンテナンス設定',
      link: '/maintenance'
    },
    {
      name: '月間レポート',
      link: '/report'
    }
  ],
  // 更新間隔（単位：分）
  TIME_INTERVAL: 5,
  // 現在人数取得時間帯（FROM）
  NOT_UPDATED_PEOPLE_MINUTES: 30
}