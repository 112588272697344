import { API } from 'aws-amplify'

export let store = {
    state: {
        message: 'this is sample message',
        serviceProviderId: -1,
        timeridForFacilityInfo: -1,
        facilityInfoData: [],
        currentPeopleData: [],
    },
    // 事業者ID
    getServiceProviderIdStored() {
        return this.state.serviceProviderId
    },
    setServiceProviderIdStored(id) {
        this.state.serviceProviderId = id
    },

    // 現在人数データ
    getCurrentPeopleDataStored() {
        return this.state.currentPeopleData;
    },
    setCurrentPeopleStored(data) {
        this.state.currentPeopleData = [];
        for (var items in data) {
            this.state.currentPeopleData.push(data[items]);
        }
    },
    clearCurrentPeopleStored() {
        this.currentPeopleData = new Array();
    },

    // 施設情報データ
    getFacilityInfoDataStored() {
        return this.state.facilityInfoData;
    },
    setFacilityInfoDataStored(data) {
        this.state.facilityInfoData = [];
        for (var items in data) {
            this.state.facilityInfoData.push(data[items]);
        }
    },
    clearFacilityInfoDataStored() {
        this.facilityInfoData = new Array();
    },

    // 施設情報取得API
    async doApiGetFacilityInfoData(userInfo) {

        // API Gatewayの名称
        const apiName = 'iotsstmdevtest201211';
        // API Gatewayの設定パス
        const apiPathForGetFacilityInfo = '/GetFacilityInfoCustomer' + '/' + this.state.serviceProviderId;

        if (userInfo != null) {
            let myInit = {
                headers: {},
                response: true,
            }

            await API.get(apiName, apiPathForGetFacilityInfo, myInit).then(response => {
                let decodedJsonData = JSON.parse(JSON.stringify(response.data));

                //ソート
                decodedJsonData.sort(function(a,b){
                    var dateA = Number(a.sort_order);
                    var dateB = Number(b.sort_order);
                    if (dateA > dateB) return 1;
                    if (dateA < dateB) return -1;
                        return 0;
                    });

                // 施設情報のstore保存
                this.setFacilityInfoDataStored(decodedJsonData);

            }).catch(error => {
                this.clearFacilityInfoDataStored();
                let errmsg = "施設情報の取得に失敗しました。";
                console.log(errmsg + error);
            });
        }
    }
}