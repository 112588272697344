<template>
  <div class='facility'>
    <v-container class="pa-0">
      <div style="min-height: 200px">
        <chart v-if="state.loaded && state.loadedPast && chartShowFlag"
          :chartdata="state.chartdata"
          :options="!$vuetify.breakpoint.xs ? state.options : state.spOptions"
          :height="!$vuetify.breakpoint.xs ? 120 : 240"></chart>
      </div>
    </v-container>
  </div>
</template>

<script>
    import Chart from './Chart';
    // import Datepicker from "vuejs-datepicker";
    import ja from "vuejs-datepicker/dist/locale";
    // import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue';
    import API from '@aws-amplify/api';
    import moment from "moment";
    import { reactive, onMounted} from '@vue/composition-api';
    import { store } from '../store/store.js';

    export default {
        name: 'Facility',
        components: {
          Chart
        },
        props: {
          ruleName: null
        },
        created() {
        },
        data() {
            return {
                ja:ja,
                chartType: 0,
                chartStartDatePicker: false,
                chartEndDatePicker: false,
                chartShowFlag: true
            }
        },
        methods: {
          chartDateChange: function (value) {
            if (value === 0) {
              this.state.startDatepicker = moment().format('yyyy-MM-DD')
              this.state.endDatepicker = moment().format('yyyy-MM-DD')
            } else if (value === 1) {
              this.state.startDatepicker = moment().subtract(1, 'd').format('yyyy-MM-DD')
              this.state.endDatepicker = moment().subtract(1, 'd').format('yyyy-MM-DD')
            } else if (value === 2) {
              this.state.startDatepicker = moment().subtract(6, 'd').format('yyyy-MM-DD')
              this.state.endDatepicker = moment().format('yyyy-MM-DD')
            }
            this.getChartData()
          }
        },
        mounted() {
          const self = this
          this.chartShowFlag = false
          this.state.chartdata.datasets[0].label = this.$t('過去３週間の混雑状況')
          this.state.options.scales.xAxes[0].scaleLabel.labelString = this.$t('営業時間')
          setTimeout(function () {
            self.chartShowFlag = true
          }, 100);
        },
        watch: {
          ruleName: function (v) {
            console.log(v)
            this.getChartData()
          },
          '$i18n.locale': function () {
            const self = this
            this.chartShowFlag = false
            this.state.chartdata.datasets[0].label = this.$t('過去３週間の混雑状況')
            this.state.options.scales.xAxes[0].scaleLabel.labelString = this.$t('営業時間')
            setTimeout(function () {
              self.chartShowFlag = true
            }, 100);
          }
        },
        setup(props, context) {
            // Vue Composition API
            const route = context.root.$route

            const state = reactive({

                globalFacilityInfoData: [],
                selectFacility: null,
                startDatepicker: moment().format('yyyy-MM-DD'),
                endDatepicker: moment().format('yyyy-MM-DD'),
                datePickerFormat: 'yyyy-MM-dd',
                timePickerFormat: 'HH:mm',
                startTime: '06:00:00',
                endTime: '23:00:00',
                loaded: false,
                loadedPast: false,
                chartPast1th : [],
                chartPast2nd : [],
                chartPast3rd : [],
                chartPastCulcCount : 0,
                errorMessage: '',
                chartdata: {
                  labels: [],
                  datasets: [
                    // {
                    //   label: '利用人数',
                    //   data:[],
                    //   borderColor: "#ff5252",
                    //   fill: false,
                    //   type: 'line',
                    //   lineTension: 0.3,
                    // },
                    {
                      label: '過去３週間の混雑状況',
                      data: [],
                      backgroundColor: [], //#f6ad49
                      borderColor: [],
                      borderWidth: 1,
                      type: 'bar'
                    }
                  ]
                },
                options: {
                  tooltips: {
                    enabled: false,
                    callbacks: {
                      title: () => 'Tile',
                      label: () => null,
                      footer: () => 'Footer'
                    }
                  },
                  hover: {mode: null},
                  scales: {
                    xAxes: [{
                      type: 'time',
                      time: {
                        unit: 'hour'
                      },
                      scaleLabel: {
                        display: true,
                        labelString: '営業時間'
                      }
                    }],
                    yAxes: [{
                      ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                        display: false
                      },
                      scaleLabel: {
                        display: false
                      }
                    }]
                  }
                },
                spOptions: {
                  tooltips: {
                    enabled: false,
                    callbacks: {
                      title: () => 'Tile',
                      label: () => null,
                      footer: () => 'Footer'
                    }
                  },
                  hover: {mode: null},
                  scales: {
                    xAxes: [{
                      type: 'time',
                      time: {
                        unit: 'hour'
                      },
                      scaleLabel: {
                        display: false
                      },
                      categoryPercentage: 0.95,
                      barPercentage: 0.95
                    }],
                    yAxes: [{
                      gridLines: {
                        display: false
                      },
                      ticks: {
                        beginAtZero: true,
                        stepSize: 10,
                        display: false
                      },
                      scaleLabel: {
                        display: false
                      }
                    }]
                  }
                }
            })

            onMounted(() => {
              try {
                  var timerExecList = [];
                  
                  //タイマー
                  var timerid = setInterval(function () {
                      if (Object.keys(store.getFacilityInfoDataStored()).length != 0) {
                          // storeから施設情報を取得する
                          getFacilityInfoData();

                          state.selectFacility = state.globalFacilityInfoData[0];
                          if(route.params.id){
                            let found = state.globalFacilityInfoData.find(e => e.facility_id == route.params.id);
                            if(found){
                              state.selectFacility = found;
                            }
                          }
                          // チャート用データを取得
                          getChartData();

                          // タイマークリア
                          clearInterval(timerid);
                      }
                  }, 200)
                  timerExecList.push(timerid);

              } catch(error) {
                console.log(error);
                for (var timer in timerExecList) {
                  clearInterval(timer);
                }
              }
            })

            /**
             * storeから施設情報を取得する。
             */
            function getFacilityInfoData() {
              state.globalFacilityInfoData = [];
              for (var items in store.getFacilityInfoDataStored()) {
                  state.globalFacilityInfoData.push(store.getFacilityInfoDataStored()[items]);
              }
            }

            /**
             * チャート用データを取得する。
             */
            function getChartData(){
              state.errorMessage = '';
  
              if(moment(state.endDatepicker).diff(moment(state.startDatepicker), 'days') > 7){
                state.errorMessage = '開始日と終了日は１週間（７日）以内にしてください。';
                return;
              }

              state.loaded = false;
              state.loadedPast = false;

              getChartPastData();
                
            }

            /**
             * チャートの過去データを取得する。
             */
            async function getChartPastData() {
              state.chartdata.datasets[0].data = []
              const apiName = 'iotsstmdevtest201211';
              const param = {
                  startDt: moment(state.startDatepicker).format('yyyy-MM-DD'),
                  endDt: moment(state.endDatepicker).format('yyyy-MM-DD')
              }
              const myInit = {
                headers: {},
                response: true,
                queryStringParameters: param
              }
              const url = '/get3WeeksMeasureCustomer/' + props.ruleName
              let targetDate
              let averageResponse = {}
              let retChartPastData = []
              var newDatasets = state.chartdata;

              const xArray = [...Array(24)].map((v, i) => i)
              await API.get(apiName, url, myInit).then(result => {
                averageResponse = result.data.target_date_summary[0].latest_3weeks
                targetDate = result.data.target_date_summary[0].target_date
              })

              state.chartPastCulcCount = 0;
              state.loadedPast = false;
              state.chartPast1st = averageResponse[0] ? averageResponse[0].hourly_measures.map(v => {
                return {
                  x: v.hour,
                  y: v.measure_people
                }
              }) : [];
              state.chartPast2nd = averageResponse[1] ? averageResponse[1].hourly_measures.map(v => {
                return {
                  x: v.hour,
                  y: v.measure_people
                }
              }) : [];
              state.chartPast3rd = averageResponse[2] ? averageResponse[2].hourly_measures.map(v => {
                return {
                  x: v.hour,
                  y: v.measure_people
                }
              }) : [];
              state.errorMessage = '';
              console.log(xArray, state.chartPast1st)

              await Promise.all(xArray.map(v => {
                if (v > 5) {
                  let aveY = 0;
                  const y1 = state.chartPast1st.length > 0 && state.chartPast1st.filter(w => w.x === v).length > 0 && (state.chartPast1st.find(w => w.x === v).y || state.chartPast1st.find(w => w.x === v).y === 0) ? state.chartPast1st.find(w => w.x === v).y : -1
                  const y2 = state.chartPast2nd.length > 0 && state.chartPast2nd.filter(w => w.x === v).length > 0 && (state.chartPast2nd.find(w => w.x === v).y || state.chartPast2nd.find(w => w.x === v).y === 0) ? state.chartPast2nd.find(w => w.x === v).y : -1
                  const y3 = state.chartPast3rd.length > 0 && state.chartPast3rd.filter(w => w.x === v).length > 0 && (state.chartPast3rd.find(w => w.x === v).y || state.chartPast3rd.find(w => w.x === v).y === 0) ? state.chartPast3rd.find(w => w.x === v).y : -1
                  const cnt = (y1 === -1 ? 0 : 1) + (y2 === -1 ? 0 : 1) + (y3 === -1 ? 0 : 1)
                  aveY = cnt !== 0 ? Math.ceil((((y1 === -1 ? 0 : y1) + (y2 === -1 ? 0 : y2) + (y3 === -1 ? 0 : y3)) / cnt)) : 0
                  retChartPastData.push({x: moment(targetDate).format('yyyy-MM-DD') + 'T' + (('00' + v).slice(-2)) + ':00:00+0900', y: aveY});
                }
              }))
              
              retChartPastData.forEach(ret => {
                console.log(ret, moment().hour(), moment(ret.x).hour())
                newDatasets.datasets[0].data.push(ret);
                newDatasets.datasets[0].backgroundColor.push(moment().hour() === moment(ret.x).hour() ? '#f6ad49' : '#a0d8ef');
                newDatasets.datasets[0].borderColor.push(moment().hour() === moment(ret.x).hour() ? '#f6ad49' : '#a0d8ef');
              });
              console.log(newDatasets)
              state.chartdata = newDatasets;
              state.loadedPast = true
              state.loaded = true;
  
            }

            return {
              state,
              getFacilityInfoData,
              getChartData,
              getChartPastData,
            }
        }
    }
</script>
<style scoped>

.chart-date-box {
  height: 28px;
  border-radius: 4px;
}

.chart-date-text {
  line-height: 26px
}

</style>