<template>
  <div id="dashboard" class="background-color-white" :class="{'px-8': !$vuetify.breakpoint.xs, 'px-3': $vuetify.breakpoint.xs}">
    <v-container class="main-background-color" style="min-height:100vh;;" >
      <div class="padding-header"></div>

      <div class="pt-56px text-center">
        <h1 :class="{'font-size-28': $vuetify.breakpoint.xs}">{{ $t('ご利用状況') }}</h1>
      </div>

      <div v-show="$vuetify.breakpoint.xs" class="text-center">
        <div v-if="$i18n.locale === 'ja'">{{now.format('YYYY年 M月 D日 HH:mm 現在')}}</div>
        <div v-else>{{now.format('hh:mmA DD/MM/YYYY')}}</div>
      </div>
  
      <div class="pt-8" v-if="state.activeBtn.items">
        <div class="info-box background-color-white ma-auto position-relative" :class="{'sp': $vuetify.breakpoint.xs}">
          <div class="position-absolute" style="top:0; z-index:1; width:100%; height: 100%;" v-if="state.loaded">
            
            <div  v-if="state.activeBtn.items.length > 1" class="text-center" :class="{'flx-center height-25-p': $vuetify.breakpoint.xs, 'flx-center height-25-p pb-1 pt-5 flx-end': !$vuetify.breakpoint.xs}">
              <h2 class="px-0" :class="{'pb-1 info-box-title': $vuetify.breakpoint.xs, 'title-font-small': ($i18n.locale === 'ja' ? state.activeBtn.groupName.length > 28 : state.activeBtn.groupNameEn.length > 28) && $vuetify.breakpoint.xs}">{{$i18n.locale === 'ja' ? state.activeBtn.groupName : state.activeBtn.groupNameEn}}</h2>
            </div>
            <div class="text-center" :class="{'flx-center pt-8 height-25-p': $vuetify.breakpoint.xs, 'flx-center height-32-p pb-1 pt-5 flx-end': !$vuetify.breakpoint.xs}" v-else >
              <h2 class="px-0" :class="{'pb-1 info-box-title': $vuetify.breakpoint.xs, 'title-font-small': ($i18n.locale === 'ja' ? state.activeBtn.groupName.length > 28 : state.activeBtn.groupNameEn.length > 28) && $vuetify.breakpoint.xs}">{{$i18n.locale === 'ja' ? state.activeBtn.groupName : state.activeBtn.groupNameEn}}</h2>
            </div>

            <div v-if="state.activeBtn.items.length > 1" :class="{'flx-center height-65-p ': $vuetify.breakpoint.xs, 'pt-0 info-box-two-icon-area-pb height-75-p flx-center': !$vuetify.breakpoint.xs}">
              <div class="width-49-p py-4 pl-5">
                <div class="info-box-chip-red ma-auto font-bold">{{ $t('女湯') }}</div>
                <div class="pt-6" :class="{'px-6': $vuetify.breakpoint.xs, 'px-10': !$vuetify.breakpoint.xs}">
                  <div v-if="!state.activeBtn.items.find(v => v.category_id === '3').people && state.activeBtn.items.find(v => v.category_id === '3').people !== 0" class="text-center">
                    <img src="@/assets/dashboard/maintenance.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[0])}}</div>
                  </div>
                  <div v-else-if="state.activeBtn.items.find(v => v.category_id === '3').mainte_status_id === '1'" class="text-center">
                    <img src="@/assets/dashboard/maintenance.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[0])}}</div>
                  </div>
                  <div v-else-if="state.activeBtn.items.find(v => v.category_id === '3').mainte_status_id === '2'" class="text-center">
                    <img src="@/assets/dashboard/unavailable.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[1])}}</div>
                  </div>
                  <div v-else-if="isOffhours(state.activeBtn.items.find(v => v.category_id === '3'))" class="text-center">
                    <img src="@/assets/dashboard/offhours.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[2])}}</div>
                  </div>
                  <div v-else-if="state.activeBtn.items.find(v => v.category_id === '3').mainte_status_id === '0' && state.activeBtn.items.find(v => v.category_id === '3').people <= state.activeBtn.items.find(v => v.category_id === '3').threshold_congestion_1" class="text-center">
                    <img src="@/assets/dashboard/use_human_blue_1.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[3])}}</div>
                  </div>
                  <div v-else-if="state.activeBtn.items.find(v => v.category_id === '3').mainte_status_id === '0' && state.activeBtn.items.find(v => v.category_id === '3').threshold_congestion_2 <= state.activeBtn.items.find(v => v.category_id === '3').people" class="text-center">
                    <img src="@/assets/dashboard/use_human_red_3.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[5])}}</div>
                  </div>
                  <div v-else class="text-center">
                    <img src="@/assets/dashboard/use_human_yellow_2.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[4])}}</div>
                  </div>
                </div>
              </div>
              
              <div class="info-box-border-center"></div>
  
              <div class="width-49-p py-4 pr-5">
                <div class="info-box-chip-blue ma-auto font-bold">{{ $t('男湯') }}</div>
                <div class="pt-6" :class="{'px-6': $vuetify.breakpoint.xs, 'px-10': !$vuetify.breakpoint.xs}">
                  <div v-if="!state.activeBtn.items.find(v => v.category_id === '2').people && state.activeBtn.items.find(v => v.category_id === '2').people !== 0" class="text-center">
                    <img src="@/assets/dashboard/maintenance.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[0])}}</div>
                  </div>
                  <div v-else-if="state.activeBtn.items.find(v => v.category_id === '2').mainte_status_id === '1'" class="text-center">
                    <img src="@/assets/dashboard/maintenance.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[0])}}</div>
                  </div>
                  <div v-else-if="state.activeBtn.items.find(v => v.category_id === '2').mainte_status_id === '2'" class="text-center">
                    <img src="@/assets/dashboard/unavailable.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[1])}}</div>
                  </div>
                  <div v-else-if="isOffhours(state.activeBtn.items.find(v => v.category_id === '2'))" class="text-center">
                    <img src="@/assets/dashboard/offhours.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[2])}}</div>
                  </div>
                  <div v-else-if="state.activeBtn.items.find(v => v.category_id === '2').mainte_status_id === '0' && state.activeBtn.items.find(v => v.category_id === '2').people <= state.activeBtn.items.find(v => v.category_id === '2').threshold_congestion_1" class="text-center">
                    <img src="@/assets/dashboard/use_human_blue_1.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[3])}}</div>
                  </div>
                  <div v-else-if="state.activeBtn.items.find(v => v.category_id === '2').mainte_status_id === '0' && state.activeBtn.items.find(v => v.category_id === '2').threshold_congestion_2 <= state.activeBtn.items.find(v => v.category_id === '2').people" class="text-center">
                    <img src="@/assets/dashboard/use_human_red_3.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[5])}}</div>
                  </div>
                  <div v-else class="text-center">
                    <img src="@/assets/dashboard/use_human_yellow_2.png" :class="{'info-box-sp-two-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-two-col-icon-size': !$vuetify.breakpoint.xs}"/>
                    <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[4])}}</div>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="flx-center" :class="{'pt-2 height-56-p': $vuetify.breakpoint.xs, 'pt-0 info-box-one-icon-area-pb height-68-p ': !$vuetify.breakpoint.xs}" v-else>
              <div v-if="!state.activeBtn.items[0].people && state.activeBtn.items[0].people !== 0" class="text-center">
                <img src="@/assets/dashboard/maintenance.png" :class="{'info-box-sp-one-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-one-col-icon-size': !$vuetify.breakpoint.xs}"/>
                <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[0])}}</div>
              </div>
              <div v-else-if="state.activeBtn.items[0].mainte_status_id === '1'" class="text-center">
                <img src="@/assets/dashboard/maintenance.png" :class="{'info-box-sp-one-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-one-col-icon-size': !$vuetify.breakpoint.xs}"/>
                <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[0])}}</div>
              </div>
              <div v-else-if="state.activeBtn.items[0].mainte_status_id === '2'" class="text-center">
                <img src="@/assets/dashboard/unavailable.png" :class="{'info-box-sp-one-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-one-col-icon-size': !$vuetify.breakpoint.xs}"/>
                <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[1])}}</div>
              </div>
              <div v-else-if="isOffhours(state.activeBtn.items[0])" class="text-center">
                <img src="@/assets/dashboard/offhours.png" :class="{'info-box-sp-one-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-one-col-icon-size': !$vuetify.breakpoint.xs}"/>
                <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[2])}}</div>
              </div>
              <div v-else-if="state.activeBtn.items[0].mainte_status_id === '0' && state.activeBtn.items[0].people <= state.activeBtn.items[0].threshold_congestion_1" class="text-center">
                <img src="@/assets/dashboard/use_human_blue_1.png" :class="{'info-box-sp-one-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-one-col-icon-size': !$vuetify.breakpoint.xs}"/>
                <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[3])}}</div>
              </div>
              <div v-else-if="state.activeBtn.items[0].mainte_status_id === '0' && state.activeBtn.items[0].threshold_congestion_2 <= state.activeBtn.items[0].people" class="text-center">
                <img src="@/assets/dashboard/use_human_red_3.png" :class="{'info-box-sp-one-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-one-col-icon-size': !$vuetify.breakpoint.xs}"/>
                <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[5])}}</div>
              </div>
              <div v-else class="text-center">
                <img src="@/assets/dashboard/use_human_yellow_2.png" :class="{'info-box-sp-one-col-icon-size': $vuetify.breakpoint.xs, 'info-box-pc-one-col-icon-size': !$vuetify.breakpoint.xs}"/>
                <div class="pt-2" :class="{'font-size-12': $vuetify.breakpoint.xs, 'font-size-14': !$vuetify.breakpoint.xs}">{{$t(state.textList[4])}}</div>
              </div>
            </div>
          </div>
          <div class="position-absolute" style="top:0; z-index:1; width:100%; height: 100%;margin-top: 48%; text-align: center"  v-else>
            <v-progress-circular indeterminate color="info"></v-progress-circular>
          </div>
        </div>
      </div>

      <div class="text-center pt-10 pb-8">
        <v-btn depressed color="white" style="text-transform: none" :class="{'btn-sp font-bold': $vuetify.breakpoint.xs, 'btn-pc font-bold': !$vuetify.breakpoint.xs}" @click="chartDialog = true">
          <span class="px-10">{{ $t('混雑状況詳細') }}</span>
          <v-icon size="20px" right>mdi-arrow-right-drop-circle</v-icon>
        </v-btn>

        <v-dialog scrollable v-model="chartDialog" width="640px" :fullscreen="$vuetify.breakpoint.xs">
          <v-card>
            <v-card-title class="dialog-title px-0 py-0">
              <div class="width-100-p">
                <v-toolbar class="px-4" flat color="info" dark>
                  <v-toolbar-title>
                    <span style="color: white">{{ $t('混雑状況詳細') }}</span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="chartDialog = false"><v-icon size="36px">mdi-close</v-icon></v-btn>
                </v-toolbar>
              </div>
            </v-card-title>
    
            <v-card-text class="px-5 py-10">
              <div class="border-bottom pb-1 mb-10">
                <span class="font-size-14">{{ $t('施設') }}</span>
                <span class="pl-2 font-size-20 font-bold">{{$i18n.locale === 'ja' ? state.activeBtn.groupName : state.activeBtn.groupNameEn}}</span>
              </div>

              <div v-if="!$vuetify.breakpoint.xs">
                <div v-if="state.activeBtn.items.length > 1" class="border-box width-480 text-center font-bold ma-auto border-radius-20px mb-8" style="border-color: #c1c1c1;">
                  <v-btn rounded depressed class="width-50-p font-bold" dark :color="chartActiveBtn === 0 ? '#178cff' : 'transparent'" :class="{'font-color-grey': chartActiveBtn != 0}" @click="chartActiveBtn = 0">{{ $t('男湯') }}</v-btn>
                  <v-btn rounded depressed class="width-50-p font-bold" dark :color="chartActiveBtn === 1 ? '#D2195E' : 'transparent'" :class="{'font-color-grey': chartActiveBtn != 1}" @click="chartActiveBtn = 1">{{ $t('女湯') }}</v-btn>
                </div>

                <div v-if="state.activeBtn.items.length > 1">
                  <facility :ruleName="state.activeBtn.items.find(v => v.category_id === (chartActiveBtn === 0 ? '2' : '3')).rule_name"></facility>
                </div>
                <div v-else>
                  <facility :ruleName="state.activeBtn.items[0].rule_name"></facility>
                </div>
              </div>

              <div v-else>
                <div v-if="state.activeBtn.items.length > 1">
                  <div class="info-box-chip-blue ma-auto width-100-p font-bold font-size-16 py-1 mb-6">{{ $t('男湯') }}</div>
                  <facility :ruleName="state.activeBtn.items.find(v => v.category_id === '2').rule_name"></facility>

                  <div class="info-box-chip-red ma-auto width-100-p font-bold font-size-16 py-1 mb-6 mt-8">{{ $t('女湯') }}</div>
                  <facility :ruleName="state.activeBtn.items.find(v => v.category_id === '3').rule_name"></facility>
                </div>

                <div v-else>
                  <facility :ruleName="state.activeBtn.items[0].rule_name"></facility>
                </div>
              </div>
  
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>

      <div v-if="state.dispGroupList.length > 1" class="pt-6 pb-10">
        <v-row class="pa-0" justify="center" align-content="center">
          <div v-for="(item) in state.dispGroupList" :key="item.facility_id" class="text-center pt-4" :class="{'px-2': $vuetify.breakpoint.xs, 'px-4': !$vuetify.breakpoint.xs}" height="100vwmin">
            <v-btn fab depressed width="128px" height="128px" :color="state.activeBtn.groupId === item.groupId ? '#0058A9' : '#fff'" :dark="state.activeBtn.groupId === item.groupId" @click="state.activeBtn = item">
              <div class="place-btn-text font-bold" :class="{'title-font-small-btn': ($i18n.locale === 'ja' ? item.groupName.length > 20 : item.groupNameEn.length > 20)}">{{$i18n.locale === 'ja' ? item.groupName : item.groupNameEn}}</div>
            </v-btn>
          </div>
          
        </v-row>
      </div>

      <div class="pt-8">
        <div v-for="(item, index) of linkList" :key="index" class="py-2 px-1 font-size-14">
          <v-card flat v-if="item.url" class="mx-auto text-center" @click="urlJump(item.url)" max-width="360px" height="56px">
            <div class="pt-5">
              <div class="card-title item-inline pl-2">{{$i18n.locale === 'ja' ? item.text : item.textEn}}</div>
              <v-icon v-if="item.text" size="18px" class="px-2">mdi-arrow-right-drop-circle</v-icon>
            </div>
          </v-card>
        </div>
      </div>

      <div class="pt-10 pb-10"></div>

    </v-container>

  </div>
</template>

<style scoped>

.info-box {
  height: 420px;
  width: 420px;
  border-radius: 100%;
}

.info-box.sp {
  height: 86vw;
  width: 86vw;
  max-height: 420px;
  max-width: 420px;
  border-radius: 100%;
}

.info-box-border-right {
  border-right: solid 1px #afafaf;
}

.info-box-border-left {
  border-left: solid 1px #afafaf;
}

.info-box-border-center {
  width: 1px;
  height: 100%;
  background-color: #7d7d7d;
}

.info-box-chip-red {
  width: 80px;
  background-color: #D2195E;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
}

.info-box-chip-blue {
  width: 80px;
  background-color: #178cff;
  color: #fff;
  border-radius: 8px;
  text-align: center;
  font-size: 14px;
}

.info-box-one-icon-area-pb {
  padding-bottom: 102px;
}

.info-box-two-icon-area-pb {
  padding-bottom: 84px;
}

.info-box-pc-two-col-icon-size {
  width: 98px;
  height: 98px;
}

.info-box-sp-two-col-icon-size {
  width: 20vw;
  height: 20vw;
  max-width: 74px;
  max-height: 74px;
}

.info-box-pc-one-col-icon-size {
  width: 112px;
  height: 112px;
}

.info-box-sp-one-col-icon-size {
  max-width: 102px;
  max-height: 102px;
}



.info-box-title {
  font-size: clamp(18px, 2vw, 22px);
}


.help-btn {
  vertical-align: baseline;
}

.help-item {
  border-bottom: solid 1px #afafaf;
  padding: 16px 0;
}

.help-item-no-border {
  padding: 16px 0;
}

.help-item-text {
  display: inline-block !important;
  padding: 16px 16px;
  vertical-align: bottom;
  font-size: 16px;
}

.card-btn {
  position: absolute !important;
  right: 16px;
}

.card-user-text {
  font-size: 40px;
  height: 64px;
  line-height: 40px;
  text-align: center;
  padding: 12px 0;
  border-right: solid 1px #afafaf;
}

.card-status-img {
  border-left: solid 1px #afafaf;
  height: 64px;
  text-align: center;
  padding: 8px 0;
}

.place-btn-text {
  font-size:14px;
  width:107px;
  white-space: break-spaces;
  word-break: keep-all;
}

.btn-pc{
  width:360px;
  height: 60px !important;
}

.btn-sp{
  width:84vw;
  max-width: 360px;
  height: 60px !important;
}

.title-font-small {
  font-size: 14px !important;
  word-break: keep-all;
}

.title-font-small-btn {
  font-size: 12px !important;
  word-break: keep-all;
}

</style>

<script>
import { API } from 'aws-amplify';
import { reactive, computed, onMounted } from '@vue/composition-api';
import { store } from '../store/store.js';
import moment from "moment";
import constants from '../common/constants';
import facility from './FacilityDetail.vue'

export default {
  name: 'Dashboard',
  components: {
    facility
  },
  props: {
    now: null
  },
  data() {
    return {
      pcIconTwoCol: '98px',
      helpDialog: false,
      chartDialog: false,
      chartActiveBtn: 0,
      ServiceProviderList : {},
      facilityInfoData: [],
      testData: [],
      linkList: []
      // time: 60 * constants.TIME_INTERVAL
    }
  },
  created: async function () {
    await this.getFacilityInfoData()
    if (process.env.VUE_APP_AWS_LINK_URL_1) {
      this.linkList.push({
        url: process.env.VUE_APP_AWS_LINK_URL_1,
        text: process.env.VUE_APP_AWS_LINK_URL_TITLE_1,
        textEn: process.env.VUE_APP_AWS_LINK_URL_TITLE_1_EN
      })
    }
    if (process.env.VUE_APP_AWS_LINK_URL_2) {
      this.linkList.push({
        url: process.env.VUE_APP_AWS_LINK_URL_2,
        text: process.env.VUE_APP_AWS_LINK_URL_TITLE_2,
        textEn: process.env.VUE_APP_AWS_LINK_URL_TITLE_2_EN
      })
    }
    if (process.env.VUE_APP_AWS_LINK_URL_3) {
      this.linkList.push({
        url: process.env.VUE_APP_AWS_LINK_URL_3,
        text: process.env.VUE_APP_AWS_LINK_URL_TITLE_3,
        textEn: process.env.VUE_APP_AWS_LINK_URL_TITLE_3_EN
      })
    }
    console.log(process.env)
    // setInterval(() => { this.time-- }, 1000)
  },
  mounted: async function () {
    console.log(this.state.globalFacilityInfoData)
    let cameraKeyArray = new Array()
    for (var data in this.state.globalFacilityInfoData) {
      cameraKeyArray.push(this.state.globalFacilityInfoData[data]["rule_name"]);
    }
    this.doApiGetLatestPeople(cameraKeyArray)
  },
  filters: {
    dateUpdated: function(reportTime) {
      moment.locale('ja', {
        weekdays: ["日曜日","月曜日","火曜日","水曜日","木曜日","金曜日","土曜日"],
        weekdaysShort: ["日","月","火","水","木","金","土"],
      });
      if (!reportTime) { return ""; }
      var format = 'M月D日 (ddd) H時m分現在';
      var dateStr = moment(reportTime).format(format);
      return dateStr;

    }
  },
  methods: {
  },
  computed: {
    isOffhours: function() {
      return function(items) {
        var format = 'HH:mm:ss';
        var nowTimeStr = moment().format(format);
        if(moment(nowTimeStr, format).isBetween(moment(items.open_time1, format), moment(items.close_time1, format))){
          return false;
        }
        if(moment(nowTimeStr, format).isBetween(moment(items.open_time2, format), moment(items.close_time2, format))){
          return false;
        }
        if(moment(nowTimeStr, format).isBetween(moment(items.open_time3, format), moment(items.close_time3, format))){
          return false;
        }
        return true;
      }
    },
    isNotUpdate: function() {
      return function(reportTime) {
        let dateComp = new Date();
        dateComp.setMinutes(dateComp.getMinutes() - constants.NOT_UPDATED_PEOPLE_MINUTES);
        if(moment(reportTime) < moment(dateComp)){
          return true;
        } else {
          return false;
        }
      }
    }
  },
  watch: {
    // time: async function(v) {
    //   if (v <= 0) {
    //     await this.getFacilityInfoData()
    //     let cameraKeyArray = new Array();
    //     for (var data in this.state.globalFacilityInfoData) {
    //       cameraKeyArray.push(this.state.globalFacilityInfoData[data]["rule_name"]);
    //     }
    //     this.doApiGetLatestPeople(cameraKeyArray)
    //     this.$emit('timeReload')
    //     this.time = 60 * constants.TIME_INTERVAL
    //   }
    // },
    'state.globalFacilityInfoData': {
      handler: async function (value) {
        const list = []
        await Promise.all(value.map(v => {
          if (list.length === 0 || !list.find(w => w.groupId === v.disp_group_id)) {
            list.push({
              groupId: v.disp_group_id,
              groupName: v.disp_group_name,
              groupNameEn: v.disp_group_name_en,
              items: [v]
            })
          } else {
            list.find(w => w.groupId === v.disp_group_id).items.push(v)
          }
          return v
        }))
        this.state.dispGroupList = list
        if (this.state.firstTime) {
          if (this.$route.query.group) {
            this.state.activeBtn = this.state.dispGroupList.length >= parseInt(this.$route.query.group) && parseInt(this.$route.query.group) > 0 ? this.state.dispGroupList[parseInt(this.$route.query.group) - 1] : this.state.dispGroupList[0]
            this.state.firstLoaded = true
          } else {
            this.state.activeBtn = this.state.dispGroupList[0]
            this.state.firstLoaded = true
          }
        }
        this.state.firstTime = false
      },
      deep: true
    }
  }

  // Vue Composition API
  ,setup(props, context) {
    const route = context.root.$route

    const state = reactive({
      globalFacilityInfoData: [],
      dispGroupList: [],
      activeBtn: {
        id: 0,
        items: []
      },
      firstTime: true,
      firstLoaded: false,
      loaded: false,
      peopleTimer: null,
      textList: [
        '調整中です',
        '現在利用できません',
        '営業時間外です',
        '空いております',
        '少々混み合っております',
        '混み合っております'
      ],
      store,
      providerName: computed(
        () => (Object.values(state.globalFacilityInfoData)[0]) ? Object.values(state.globalFacilityInfoData)[0]["service_provider_name"] : ""
      )
    })

    onMounted(async () => {
      //doExecDashBoardProc();
    })

    /**
    * storeから施設情報を取得する。
    */
    async function getFacilityInfoData() {
      const selfState = state
      state.globalFacilityInfoData = [];
      await Promise.all(store.getFacilityInfoDataStored().map(async function (v) {
        await selfState.globalFacilityInfoData.push(v)
      }))
    }

    /**
    * 現在人数の取得処理を実行する。
    */
    async function doApiGetLatestPeople(cameraKeyArray) {
      if(route.path !== '/dashboard'){
        clearInterval(state.peopleTimer);
        return;
      }
      let now = moment();

      // API Gatewayの名称
      const apiName = 'iotsstmdevtest201211';
      // API Gatewayの設定パス
      const apiPathGetLatestPeople =  '/GetLatestPeopleCustomer';
      const paramPath = apiPathGetLatestPeople;

      await API.post(
        apiName, paramPath,
        {
          headers: {},
          response: true,
          body: {
            cameraKey: cameraKeyArray,
            startDt: moment(now).subtract(constants.NOT_UPDATED_PEOPLE_MINUTES + 1, 'minutes').format(),
            endDt: moment(now).add(1, 'minutes').format(),
          }

        }).then(async function (response) {
          const decodedJsonData = JSON.parse(JSON.stringify(response.data));
          // 現在人数情報のstore保存
          await Promise.all(state.globalFacilityInfoData.map(async function (v, i) {
            await Promise.all(decodedJsonData.map(dataPeople => {
              console.log(dataPeople)
              if (dataPeople.Items && dataPeople.Items.length && v.rule_name == dataPeople.Items[0].rule_name) {
                var newData = v;
                newData.people = dataPeople.Items[0].people;
                newData.report_time = dataPeople.Items[0].report_time;
                state.globalFacilityInfoData.splice(i, 1, newData);
              }
            }))
          }))
          state.loaded = true
        }).catch(error => {
          //alert("現在人数情報の取得処理でエラーが発生しました。");
          store.clearCurrentPeopleStored();
          console.log(error);
        })
      }

      function urlJump(url) {
        if (!window.open(url)) {
          window.location.href = url
        }
      }

      return {
        state,
        getFacilityInfoData,
        doApiGetLatestPeople,
        urlJump
      }
    }
  }

  </script>
