<template>
  <v-app>
    <div>
      <header style="position: fixed; z-index:10" class="px-8">
        <v-app-bar class="head-bar container px-0" height="72px" app tile flat :class="{'px-8': $vuetify.breakpoint.sm, 'px-3': $vuetify.breakpoint.xs}">
          <!--<v-app-bar-nav-icon @click="drawer = true" class="head-nav-ico"></v-app-bar-nav-icon>-->
          <v-toolbar-title class="px-0" :class="{'width-100-p': $vuetify.breakpoint.xs}">
            <router-link tag="div" to="/" class="head-title-text pointer" v-if="!$vuetify.breakpoint.xs">
              <img v-if="!state.noImg" class="pr-1" :src="state.logo" height="32px" style="vertical-align: bottom;" @error="state.noImg = true" />
              <span style="vertical-align: super;">{{$i18n.locale === 'ja' ? state.globalFacilityInfoData[0].service_provider_name : state.globalFacilityInfoData[0].service_provider_name_en}}</span>
            </router-link>
            <router-link tag="div" to="/" class="head-title-text pointer text-center" v-else>
              <img v-if="!state.noImg" class="pr-1" :src="state.logo" height="32px" style="vertical-align: bottom;" @error="state.noImg = true" />
              <div>{{$i18n.locale === 'ja' ? state.globalFacilityInfoData[0].service_provider_name : state.globalFacilityInfoData[0].service_provider_name_en}}</div>
            </router-link>
          </v-toolbar-title>
          <!--<v-spacer></v-spacer>-->
          <!--<span class="name">{{state.providerName}}</span>-->
          <v-spacer></v-spacer>
          <div v-show="!$vuetify.breakpoint.xs" class="text-right" style="font-size: 12px">
            <div v-if="$i18n.locale === 'ja'">
              <div>{{state.today.format('YYYY年 M月 D日')}}</div>
              <div>{{state.today.format('HH時 mm分 現在')}}</div>
            </div>
            <div v-else>
              <div>{{state.today.format('hh:mmA')}}</div>
              <div>{{state.today.format('DD/MM/YYYY')}}</div>
            </div>
          </div>
          <div class="pl-4 text-center" style="font-size:12px" :class="{'sp-language-btn': $vuetify.breakpoint.xs}">
            <v-btn width="80px" x-small depressed color="#dbf0ff" class="item-inline pointer" @click="$i18n.locale = 'ja'">日本語</v-btn>
            <div class="pt-1"></div>
            <v-btn width="80px" x-small depressed color="#dbf0ff" class="item-inline pointer" @click="$i18n.locale = 'en'">English</v-btn>
          </div>
        </v-app-bar>
        
        <v-navigation-drawer v-model="drawer" fixed temporary>
          <v-list-item class="title-background px-3">
            <v-list-item-content>
              <v-list-item-title class="title">
                <v-btn icon @click="drawer = false"><v-icon>mdi-close</v-icon></v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list dense nav class="pa-0">
            <v-list-item v-for="(menuItem, index) in menuItems" :key="index" :to="menuItem.link" class="menu-item mb-0">
              <v-list-item-content>
                <v-list-item-title>
                  {{ menuItem.name }}
                  <v-icon class="menu-icon">mdi-chevron-right</v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
      </header>
  
      <v-main class="pa-0">
        <router-view v-if="state.infoGeted" @timeReload="timeReload()" :now="state.today"/>
      </v-main>

      <footer class="width-100-p background-color-white" style="position: absolute; bottom: 0;">
        <div class="text-center pb-2 width-100-p">
          <span style="font-size:12px">© 2021 tepsys</span>
        </div>
      </footer>
    </div>
  </v-app>
</template>




<style type="text/css">

/* --------------------------
Main
----------------------------*/
.v-toolbar__content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.v-application--wrap {
	background: #ECEEF1 !important;
}

.container {
	box-sizing: border-box;
}

.l-dashboard {
  margin-bottom: 20px;
}

img.guide {
  max-width: 500px;
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.page-title {
	border-bottom: solid 2px #afafaf;
}

.border-bottom {
	border-bottom: solid 1px #afafaf;
}

.border-bottom-info {
	border-bottom: solid 2px #2196f3;
}

.border-right {
	border-right: solid 1px #afafaf;
}

.border-radius-20px {
  border-radius: 20px;
}

.width-100-p {
	width: 100% !important;
}

.width-50-p {
	width: 50% !important;
}

.width-49-p {
	width: 49% !important;
}

.width-480 {
	width: 480px !important;
}

.height-75-p {
	height: 75% !important;
}

.height-68-p {
	height: 68% !important;
}

.height-65-p {
	height: 65% !important;
}

.height-56-p {
	height: 56% !important;
}

.height-32-p {
	height: 32% !important;
}

.height-25-p {
	height: 25% !important;
}

.max-width-480 {
	max-width: 480px !important;
}

.dialog-title {
	border-bottom: solid 1px #afafaf;
}

.font-size-28 {
	font-size: 28px !important;
}

.font-size-20 {
	font-size: 20px !important;
}

.font-size-16 {
	font-size: 16px !important;
}

.font-size-14 {
	font-size: 14px !important;
}

.font-size-12 {
	font-size: 12px !important;
}

.font-color-grey {
  color: #c1c1c1 !important;
}

.item-inline {
	display: inline-block !important;
}

.item-flex {
	display: flex !important;
}

.font-color-info {
	color: #2196f3;
}

.border-box-info {
	border: solid 1px #2196f3;
}

.border-box {
	border: solid 1px;
}

.border-bottom {
	border-bottom: solid 1px;
}


.font-bold {
	font-weight: bold !important;
}

.number-text-field input{
  text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-right {
	text-align: right !important;
}

.main-background-color {
  background-color: #dbf0ff;
}

.background-color-white {
  background-color: #fff
}

.padding-header {
  padding-top: 56px;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

/* --------------------------
Header
----------------------------*/
header {
	height: auto !important;
}

header span.name {
  font-size: 0.8rem;
}

.head-bar {
	background: #fff !important;
	color: #424242 !important;
}

.foot-bar {
	background: #fff !important;
	color: #424242 !important;
}

.v-toolbar__content {
	height: auto !important;
}

.v-toolbar__title {
	color: #424242;
	font-weight: bold;
	margin-left: 0 !important;
}

.head-title {
  border-left: solid 2px #eeeeee;
  height: 48px !important;
}

.head-title-text {
	font-size: 14px;
}

.head-nav-ico i {
	color: #424242 !important;
	height: 36px !important;
	width: 36px !important;
	font-size: 36px !important;
}

h2 {
	color: #414141;
	font-size: 22px;
	padding: 20px 20px 20px 0;
}

.flx-block-content {
	font-size: 12px;
	line-height: 1.7;
}

.flx {
	display: flex !important;
}

.flx-end {
  align-items: flex-end !important;
}

.flx-center{
  display: flex;
  align-items: center;
  justify-content: center;
}

.v-data-table-header{
	background-color: #F2F2F2;
}

/*.btn-sign-out {*/
/*	width: 120px;*/
/*	margin-right: 28px;*/
/*}*/

.btn-sign-out {
	border-radius: 4px !important;
	font-weight: bold !important;
}

.toolbar-items {
	height: 32px !important;
}

.pt-56px {
  padding-top: 56px;
}

.sp-language-btn {
  position: absolute;
  right: 0;
  top: -7px;
}

/* --------------------------
SideMenu
----------------------------*/

.title {
	text-align: right;
}

.title-background {
	background-color: #efefef;
	height: 36px !important;
	border-bottom: solid 2px #afafaf;
}

.menu-item {
	padding: 0px 16px !important;
	margin: 0px;
	border-bottom: solid 2px #afafaf;
	border-radius: 0px !important;
	height: 52px;
}

.menu-item div {
	font-size: 16px !important;
}

.menu-icon {
	position: absolute !important;
	right: 16px;
	top: 14px;
	color: #6f6f6f !important;
}

</style>


<script>
import { reactive, computed, onMounted} from '@vue/composition-api';
import { store } from './store/store.js';
import constants from './common/constants';
import moment from "moment";

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    menuItems: constants.menuItems,
  }),
  
  watch: {
    // 'i18n.locale' :function (v) {
    //   if (v === 'ja') {
    //     this.state.logo = '/logo/' + process.env.VUE_APP_USER_NAME + '.jpg'
    //   } else {
    //     this.state.logo = '/logo_en/' + process.env.VUE_APP_USER_NAME + '.jpg'
    //   }
    // }
  },

  // Vue Composition API
  setup() {
    const state = reactive({
      // private
      privateServiceProviderId: -1,
      // grobal
      globalFacilityInfoData: {},

      providerName: computed(
        () => (Object.values(state.globalFacilityInfoData)[0]) ? Object.values(state.globalFacilityInfoData)[0]["service_provider_name"] : ""
      ),
      today: moment(),
      infoGeted: false,
      passChangeDialog: false,
      nowPass: '',
      newPass: '',
      newPassConfirm: '',
      showNowPassword: false,
      showNewPassword: false,
      showNewPasswordConfirm: false,
      changePassForm: false,
      logo: '/logo/' + process.env.VUE_APP_USER_NAME + '.jpg',
      noImg: false,
      passwordPattern: /^(?=.*[!-/:-@[-`{-~])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[!-~]{8,20}$/,
      rules: {
        required: value => (!!value || value === 0) || '必須入力です',
        password: value => {
          const pattern = state.passwordPattern
          return pattern.test(value) || 'パスワードが簡単すぎるか、長すぎます 8～20文字、かつ半角英数字（大、小）、記号をそれぞれ1種類以上含むように入力してください。'
        },
        passConfirm: () => state.newPassConfirm === state.newPass || 'パスワードが一致しません'
      }
      
    })

    state.privateServiceProviderId = process.env.VUE_APP_USER_NAME;
    setServiceProviderId();

    onMounted(async function () {
      document.title = 'TEPSYS IoTSS'
      // 施設情報取得APIを実行
      await store.doApiGetFacilityInfoData(state.privateServiceProviderId);
      state.infoGeted = true
      getFacilityInfoData()
    })

    // getter / setter

    /**
     * storeから事業者IDを取得する。
     */
    function setServiceProviderId() {
      store.setServiceProviderIdStored(state.privateServiceProviderId)
    }

    /**
     * storeから施設情報を取得する。
     */
    function getFacilityInfoData() {
        state.globalFacilityInfoData = [];
        for (var items in store.getFacilityInfoDataStored()) {
            state.globalFacilityInfoData.push(store.getFacilityInfoDataStored()[items]);
        }
    }
    
    /**
     * 時間更新
     */
     function timeReload() {
       state.today = moment()
     }

    return {
      state,
      setServiceProviderId,
      getFacilityInfoData,
      timeReload
    }
  }

};
</script>
